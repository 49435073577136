import { getMsg } from "utils/helpers";
import * as Yup from "yup";

export const passwordSchema = {
  password: Yup.string()
    .required(getMsg("Password"))
    .min(8, "minimum of 8 characters is required"),
  confirmPassword: Yup.string()
    .required("Please retype your password.")
    .oneOf([Yup.ref("password")], "Your passwords do not match."),
};

// login schema
export const loginSchema = Yup.object({
  email: Yup.string().required(getMsg("Email")).email("Email is invalid"),
  password: Yup.string()
    .required(getMsg("Password"))
    .min(8, "Minimum of 8 characters is required"),
});

// registration schema
export const registerSchema = Yup.object({
  first_name: Yup.string().required(getMsg("First name")),
  last_name: Yup.string().required(getMsg("Last name")),
  email: Yup.string().required(getMsg("Email")).email("Email is invalid"),
  account_type: Yup.string().required(getMsg("Account category")),
  ...passwordSchema,
});

// forgot password schema
export const forgotPasswordSchema = Yup.object({
  email: Yup.string().required(getMsg("Email")).email("Email is invalid"),
});

// request invite schema

export const inviteSchema = Yup.object({
  name: Yup.string().required(getMsg("Name")),
  email: Yup.string().required(getMsg("Email")).email("Email is invalid"),
  company: Yup.string().required(getMsg("Company")),
  idCard: Yup.object({
    name: Yup.string(),
    link: Yup.string(),
  }).test({
    message: getMsg("ID card"),
    test: (fileObj) => {
      const isValid = Object.values(fileObj).filter((i) => i).length > 0;
      return isValid;
    },
  }),
});
