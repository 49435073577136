import { Controller, useForm } from 'react-hook-form';
import AuthLayout from '@components/layouts/Auth';
import Button from '@components/shared/Button';
import CustomLink from '@components/shared/CustomLink';
import TextInput from '@components/shared/TextInput';
import { ReactElement } from 'react';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { loginSchema } from 'utils/validations/auth';
import { valdationOpts } from 'utils/validations/options';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
// import Socials from '@components/shared/Social';
import { AccountStatus, AccountType, StaffRole } from 'utils/customTypes';
import { useRouter } from 'next/router';
import { useUserContextDispatch } from '@contexts/User';
import { useSWRConfig } from 'swr';
import baseurl from './api/services/baseurl';
import { NextPageWithLayout } from './_app';

const StyledLogin = styled.div`
  .password {
    margin-bottom: 8px;
  }
`;

type LoginType = {
  email: string;
  password: string;
};

const Login: NextPageWithLayout = () => {
  const { mutate } = useSWRConfig();
  const setUser = useUserContextDispatch();
  const router = useRouter();
  const validationOpt = {
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
    ...valdationOpts,
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isSubmitting },
  } = useForm<LoginType>(validationOpt);
  const onSubmit = async (data: LoginType) => {
    if (isSubmitting) return;

    try {
      const response = await baseurl.post('/login', data);
      const { token, user } = response.data.data;

      localStorage.setItem('userToken', token);

      const { account_status, current_step, account_type, role } = user;

      await mutate('/profile');

      toast.success(response.data.message);

      if (account_status === AccountStatus.NotVerified || !account_type) {
        const step = account_status === AccountStatus.NotVerified ? 1 : 2;
        setUser((state) => ({ ...state, step }));
        return await router.push('/register');
      }

      if (
        (role === StaffRole.SuperAdmin ||
          role === StaffRole.BranchLead ||
          role === StaffRole.TeamMember) &&
        current_step !== 5
      ) {
        return await router.push('/register/complete-signup');
      }

      if (account_type === AccountType.Student && current_step !== 3) {
        return await router.push('/register/complete-signup');
      }

      router.push('/dashboard');
    } catch (err) {
      const error = err as AxiosError;
      if (error.response?.status === 400) {
        toast.error('Invalid email or password');
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  return (
    <StyledLogin>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, value } }) => (
            <TextInput
              {...{
                label: 'Email',
                type: 'email',
                onChange,
                value,
                error: errors.email?.message,
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, value } }) => (
            <TextInput
              {...{
                label: 'Password',
                type: 'password',
                onChange,
                className: 'password',
                value,
                append: 'eye-closed',
                error: errors.password?.message,
              }}
            />
          )}
        />
        <CustomLink href="/forgot-password">Forgot Password?</CustomLink>
        <div className="form-action">
          <Button
            label={
              isSubmitting ? <div className="loading">logging in</div> : 'Login'
            }
            disabled={!isValid}
            type="submit"
          />
        </div>
      </form>
      {/* <h5>OR</h5>
      <Socials /> */}
      <h6 className="not-register">
        Don&apos;t have an account yet?{' '}
        <CustomLink className="text--underlined" href="/register">
          Sign up
        </CustomLink>
      </h6>
    </StyledLogin>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <AuthLayout title="Login">{page}</AuthLayout>;
};

export default Login;
