import Link from 'next/link';
import cn from 'classnames';

interface ICustomLink {
  href: string;
  children: React.ReactNode;
  className?: string;
  target?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export default function CustomLink({
  href,
  children,
  className,
  disabled,
  ...props
}: ICustomLink) {
  return (
    <Link href={href} passHref>
      <a
        {...{
          className: cn(className, {
            ['disable-link']: disabled,
          }),
          ...props,
        }}
      >
        {children}
      </a>
    </Link>
  );
}
