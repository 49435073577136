import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

export enum BtnVariant {
  Primary = 'primary',
  Success = 'success',
  Danger = 'danger',
  Outline = 'outline',
  Flat = 'flat',
}

const StyledButton = styled.button`
  white-space: nowrap;
  text-transform: capitalize;
  cursor: pointer;
  font-family: 'DM Sans';
  min-width: 127px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: var(--white);
  border-radius: 8px;
  padding: 0 42px;
  font-weight: 400;
  font-size: 14px;
  &[data-variant='primary'] {
    background-color: var(--primary);
  }
  &[data-variant='success'] {
    background-color: #0b9341;
  }
  &[data-variant='danger'] {
    background-color: #eb5757;
  }
  &[data-variant='outline'] {
    border: 1px solid var(--primary);
    color: var(--text-color-1);
  }
  &[data-variant='flat'] {
    border-color: transparent;
    background: transparent;
    padding: 0;
    min-width: auto;
    align-items: center;
    color: var(--primary);
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.04em;
    > *:hover {
      color: var(--primary);
    }
    svg {
      stroke: #7a7878;
      vertical-align: middle;
      :hover {
        stroke: var(--primary);
      }
    }
  }
  :disabled {
    border-color: var(--silver-light);
    background-color: var(--silver-light);
    color: var(--color-3);
    pointer-events: none;
  }
  &.disabled:disabled {
    border-color: transparent;
    background-color: transparent;
    color: var(--silver-light);
  }
  &.menu-btn {
    border-color: #bacdd1;
    border-radius: 4px;
    padding: 4px 10px;
    font-weight: 400;
    font-size: 10px;
    min-width: auto;
    height: auto;
  }
  &[data-size='small'] {
    min-width: 0;
    padding-left: 16px;
    padding-right: 16px;
    white-space: noWrap;
  }
  &[data-color='sucess'] {
    color: #0b9341;
  }
`;

export default function Button({
  label,
  type = 'button',
  variant = BtnVariant.Primary,
  className,
  size,
  ...props
}: {
  label: ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  variant?: BtnVariant;
  size?: 'small';
  disabled?: boolean;
  id?: string;
  style?: CSSProperties;
}) {
  return (
    <StyledButton
      {...{ ...props, type, 'data-variant': variant }}
      className={classNames(
        {
          disabled: variant === BtnVariant.Flat,
        },
        `${className || ''}`
      )}
      data-size={size}
    >
      {label}
    </StyledButton>
  );
}
