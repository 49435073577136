import classNames from 'classnames';
import { ChangeEvent, ReactNode, useMemo, useState } from 'react';
import styled from 'styled-components';
import Icons from '../Icons';

export const StyledTextInput = styled.div`
  position: relative;
  .form-control {
    /* > * {
      flex-shrink: 0;
    } */
    border: 1px solid #7a7a7a;
    border-radius: 12px;
    display: flex;
    height: 48px;
    padding: 12px 16px;
    background-color: var(--white);
    :focus-within {
      border: 1px solid var(--primary);
    }
    input {
      flex: 1;
      border: none;
      width: 100%;
      margin-top: -12px;
      height: calc(100% + 24px);
      ::placeholder {
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        color: #bacdd1;
        letter-spacing: 0.04em;
      }
      &[readonly] {
        opacity: 0.5;
      }
    }
  }
  &.error {
    .form-control {
      border-color: var(--error-color, red);
    }
  }
`;
export default function TextInput({
  value,
  onChange,
  className,
  htmlFor,
  label,
  type = 'text',
  append,
  prepend,
  error,
  placeholder,
  readOnly,
  required,
  disabled,
}: {
  className?: string;
  htmlFor?: string;
  label?: string;
  type?: string;
  append?: string;
  prepend?: ReactNode;
  value: string;
  onChange: (e: string) => void;
  error?: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
}) {
  const [show, setShow] = useState(false);
  const typeState = useMemo(() => {
    if (type !== 'password') return type;
    return show ? 'text' : 'password';
  }, [show, type]);
  return (
    <StyledTextInput
      {...{ className: classNames(className, { error: !!error }) }}
    >
      {label && (
        <label htmlFor={htmlFor || label.toLowerCase()}>
          {required && <span className="required">*</span>} {label}
        </label>
      )}
      <div className="form-control">
        {prepend && prepend}
        <input
          {...{
            type: typeState,
            name: label?.trim(),
            value,
            onChange: (e: ChangeEvent<HTMLInputElement>) => {
              if (readOnly) return;
              onChange(e.target.value);
            },
            placeholder,
            readOnly,
            disabled,
          }}
        />
        {append && (
          <span onClick={() => setShow(!show)}>
            <Icons type={append} stroke={error ? '#ff0000' : ''} />
          </span>
        )}
      </div>
      {error && <span className="error-msg"> {error}</span>}
    </StyledTextInput>
  );
}
