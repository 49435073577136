import styled from 'styled-components';
import Image from 'next/image';
import { ReactNode } from 'react';
import CustomLink from '@components/shared/CustomLink';
import Icons from '@components/shared/Icons';

const StyledAuthLayout = styled.div`
  display: flex;
  height: 100vh;

  > div {
    flex: 1;
    :first-child {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      padding: 0 16px;
      overflow-y: auto;
      padding-bottom: 20px;
    }
    :last-child {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media (max-width: 1280px) {
      :last-child {
        display: none;
      }
    }
  }
  .heading {
    margin-bottom: 32px;
  }
  .title {
    font-weight: 500;
    color: var(--primary);
  }
  .subheading {
    margin-top: 16px;
    font-size: 16px;
    line-height: 24px;
    font-family: 'DM Sans';
    font-weight: 400;
    color: var(--text-color-1);
  }
  .content {
    padding-top: 80px;
    width: 100%;
    display: flex;
    gap: 100px;
    flex-direction: column;
    @media (min-width: 524px) {
      width: 455px;
    }
  }
  .socials {
    display: flex;
    gap: 24px;
    justify-content: center;
    align-items: center;
  }
  .not-register {
    text-align: center;
    margin-top: 32px;
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color-1);
    a {
      font-size: 16px;
      text-decoration: none;
    }
  }
  h5 {
    margin-top: 32px;
    margin-bottom: 22px;
    text-align: center;
    color: var(--text-color-1);
    font-weight: 400;
  }
  .letter-spacing {
    letter-spacing: 0.04em;
  }
  .gs-wrapper {
    display: grid;
    row-gap: 16px;
  }
  .gs-btn {
    margin-top: 18px;
    font-size: 18px;
    height: 56px;
    border-radius: 12px;
  }
  .otp-btn-spacing {
    margin-top: 40px;
  }
  &.pt-50 {
    > div:first-child {
      overflow-y: scroll;
      padding-bottom: 20px;
    }
  }
  .timer-cont {
    font-size: 14px;
    a {
      font-size: 16px;
      color: var(--primary);
      font-weight: 500;
      cursor: pointer;
    }
  }
  .text-disabled {
    color: var(--color-5) !important;
    cursor: not-allowed !important;
  }
  .sso {
    font-family: 'Space Grotesk';
    font-weight: 700;
    font-size: 28px;
  }
  mt-58 {
    margin-top: 58px;
  }
`;

export default function AuthLayout({
  title,
  children,
  subheading,
  className,
}: {
  title: ReactNode;
  children: ReactNode | JSX.Element;
  subheading?: ReactNode;
  className?: string;
}) {
  return (
    <StyledAuthLayout className={className}>
      <div>
        <div className="content">
          <div className="logo">
            <CustomLink href="/">
              <Image
                src="/img/logo.png"
                alt="logo"
                width={77.44}
                height={16.76}
              />
            </CustomLink>
          </div>
          <div className="wrapper">
            <div className="heading">
              <h1 className="title">{title}</h1>
              {subheading && (
                <p className="subheading letter-spacing">{subheading}</p>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      <div>
        <Image
          src="/img/get-started.png"
          alt="get started"
          layout="fill"
          priority
        />
        <div
          style={{
            textAlign: 'center',
            color: '#fff',
            maxWidth: 445,
            fontFamily: 'space grotesk',
            position: 'absolute',
          }}
        >
          <Icons type="hand-shake" />
          <h1>Let’s get started</h1>
          <p>
            Our user-friendly interface makes it easy to get started, and our
            platform is designed to help you find the perfect job quickly and
            easily.
          </p>
        </div>
      </div>
    </StyledAuthLayout>
  );
}
